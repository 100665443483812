import React from "react";

import Layout from "../layouts/en";
import SEO from "../components/seo";

import { AccountBlocked } from "../components/TWComponents/AccountBlocked";

const AccountBlockedPage = (props) => {
  return (
    <Layout location={props.location} showBookNowButton={false}>
      <SEO
        title="Account Deactivated"
        keyword="Account Deactivated"
        decription="Account Deactivated"
      />
      <AccountBlocked
        title="Account deactivated"
        subDesciption="Your account has been deactivated. This may have occurred due to inactivity for 24 months, an invalid email used during signup, or merging with another account."
        decription='Please <a href="mailto:info@ayanarewards.com?subject=AYANA%20Rewards%20Account%20Reactivation" target="_top" className="!tw-text-[#0095D8]">contact us</a>
        to reactivate your account or assistance.'
      />
    </Layout>
  );
};

export default AccountBlockedPage;
